import axios from 'axios'

import { reatailmediaApi as baseAPI } from '../api'

const baseURL = `${baseAPI}/publisher-advertiser-tag`

/**
 * Create publisher tag
 */
export const createPublisherAdvertiserTagService = (
  body: CreatePublisherAdvertiserTagRequest
) => axios.post<CreatePublisherAdvertiserTagResponse>(baseURL, body)

/**
 * List publisher tag
 */
export const listPublisherAdvertiserTagService = (
  params: ListPublisherAdvertiserTagRequest
) => axios.get<ListPublisherAdvertiserTagResponse>(baseURL, { params })

/**
 * Get publisher tag
 */
export const getPublisherAdvertiserTagService = (
  id: PublisherAdvertiserTagId
) => axios.get<PublisherAdvertiserTagWithAccountInfo>(`${baseURL}/${id}`)

/**
 * Delete publisher tag
 */
export const deletePublisherAdvertiserTagService = (
  id: PublisherAdvertiserTagId
) => axios.delete(`${baseURL}/${id}`)
