import axios from 'axios'

import { bffRetailMedia } from '~/middlewares/axios'

import { reatailmediaApi as baseAPI } from '../api'

const baseURL = `${baseAPI}/audience`

/**
 * Get one
 */
export const getAudienceService = (
  audienceId: BrandedType<number, 'AudienceId'>
) => axios.get<AudienceData>(`${baseURL}/${audienceId}`)

/**
 * List all
 */
export const listAudiencesService = (params?: ListAudienceQueryParams) =>
  axios.get<ListAudienceResponse>(baseURL, { params })

/**
 * List all available for campaign
 */
export const listAvailableAudienceService = (
  params?: ListAvailableAudienceQueryParams
) =>
  bffRetailMedia.get<ListAvailableAudienceResponse>(
    `/campaign/audience/available`,
    { params }
  )
