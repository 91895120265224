import axios from 'axios'

import dashboard from '~/modules/retailMedia/fakes/grocery/dashboard.json'

import { reatailmediaApi as baseAPI } from '../api'

const baseURL = `${baseAPI}/campaign`

// const baseURL = `${baseAPI}/grocery/campaigns`

/**
 * LIST
 */
// export const list = () => Promise.resolve(listCampaigns)
export const list = (queries = {}) => {
  return axios.get<ResponseListGroceryCampaign>(`${baseURL}/v2`, {
    params: { ...queries }
  })
}

/**
 * VIEW
 */
export const get = ({ campaign_id: id, ...rest }) => {
  return axios.get(`${baseURL}/${id}`, { params: { ...rest } })
}

/**
 * CREATE
 */
export const create = (body: any) => console.log('create', body)
// export const create = body => {
//   return axios.post(`${baseURL}`, body)
// }

/**
 * EDIT
 */
export const update = (body: any) => console.log('update', body)
// export const update = ({ id, body }) => {
//   return axios.put(`${baseURL}/${id}`, body)
// }

/**
 * PATCH
 */
export const patch = ({
  body,
  id
}: {
  id: string
  body: { active?: boolean; status?: CampaignStatus; action: CampaignActions }
}) => axios.patch(`${baseURL}/actions/${body.action}/${id}`, body)
/**
 * UPLOAD
 */

export const upload = ({ body = {}, axiosOptions = {} }) => {
  return axios.post(`${baseURL}/upload`, body, axiosOptions)
}

/**
 * CANCEL
 */
export const cancelCampaign = (id: string) => console.log('cancel', id)
// export const remove = id => {
//   return axios.put(`${baseURL}/cancel/${id}`)
// }

/**
 * DELETE
 */
export const remove = (id: string) => console.log('remove', id)
// export const remove = id => {
//   return axios.delete(`${baseURL}/${id}`)
// }

/**
 * Dashbord
 */
export const getDashboard = () => Promise.resolve(dashboard)
// export const get = id => {
//   return axios.get(`${baseURL}/dashboard`)
// }
export const countCampaignsKey = 'count-campaigns'
export const listCountStatus = (queries = {}) => {
  return axios.get<ResponseCountReviewCampaign>(`${baseURL}/count`, {
    params: { ...queries }
  })
}

export const listCountPendingCampaigns = (queries = {}) => {
  return axios.get<ResponseCountReviewCampaign>(`${baseURL}/review/count`, {
    params: { ...queries }
  })
}
export const countCampaignByStatus = listCountStatus

export default {
  list,
  get,
  create,
  update,
  patch,
  upload,
  remove,
  cancelCampaign,
  dashboard: { get: getDashboard },
  listCountStatus
}
