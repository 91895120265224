import axios from 'axios'

import { reatailmediaApi as baseAPI } from '../api'

const baseURL = `${baseAPI}/publisher-tag`

/**
 * Create publisher tag
 */
export const createPublisherTagService = (body: CreatePublisherTagRequest) =>
  axios.post<CreatePublisherTagResponse>(baseURL, body)

/**
 * List publisher tag
 */
export const listPublisherTagService = (params?: ListPublisherTagRequest) =>
  axios.get<ListPublisherTagResponse>(baseURL, { params })

/**
 * Get publisher tag
 */
export const getPublisherTagService = (id: PublisherTagId) =>
  axios.get<PublisherTag>(`${baseURL}/${id}`)

/**
 * Patch publisher tag
 */
export const patchPublisherTagService = ({
  id,
  body
}: PatchPublisherTagRequest) => axios.patch(`${baseURL}/${id}`, body)

/**
 * Delete publisher tag
 */
export const deletePublisherTagService = (id: PublisherTagId) =>
  axios.delete(`${baseURL}/${id}`)
