import axios from 'axios'

import { reatailmediaApi as baseAPI } from '../api'

const baseURL = `${baseAPI}/campaign`

/**
 * List all
 */
export const getCampaignsKey = 'getCampaigns'
export const getCampaignsService = (params: ResquestCampaignList = {}) =>
  axios.get<ResponseCampaignList>(`${baseURL}/v2`, { params })

/**
 * List review campaigns
 */
export const getCampaignsInReviewKey = 'getCampaignsInReview'
export const getCampaignsInReviewService = (
  params: RequestCampaignInReviewList = {}
) => axios.get<ResponseCampaignInReviewList>(`${baseURL}/review`, { params })

/**
 * View
 */
export const getCampaignService = ({
  campaign_id: id,
  ...rest
}: {
  campaign_id: string
}) => {
  return axios.get<CampaignViewResponse>(`${baseURL}/${id}`, {
    params: { ...rest }
  })
}

/**
 *
 */

export const createCampaignKey = 'createCampaign'
export const createCampaignService = (body: CreateCampaignPayload) =>
  axios.post<CreateCampaignResponse>(`${baseURL}`, body)
