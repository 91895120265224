import { bffRetailMedia } from '~/middlewares/axios'

const baseURL = 'audience-enriched'

/**
 * Update
 */
export const updateAttributeAudienceService = ({
  id,
  body
}: AudienceEnrichedUpdateParams) =>
  bffRetailMedia.patch<AudienceEnrichedData>(`${baseURL}/${id}`, body)

/**
 * Get one
 */
export const getAudienceEnrichedService = (id: AudienceEnrichedData['id']) =>
  bffRetailMedia.get<AudienceEnrichedData>(`${baseURL}/${id}`)

/**
 * List all
 */
export const listAudienceEnrichedService = (
  params?: AudienceEnrichedQueryParams
) => bffRetailMedia.get<AudienceEnrichedResponse>(baseURL, { params })
